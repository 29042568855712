import React from "react"
import Content from "../components/Content"
import { Container, Hero } from "../components/Sections"
import Header from "../components/Header"

const GDPRPageTemplate = ({ heading, image, subheading, html }) => {
  return (
    <>
      <Header heading={heading} subheading={subheading} />{" "}
      <Hero image={image} />
      <Container>
        <Content html={html} />
      </Container>
    </>
  )
}

export default GDPRPageTemplate
